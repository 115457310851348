<template>
  <div v-if="currentRole === 4 || currentRole === 5" class="book-inventory mt-5">
    <h1 class="heading">Course Material Check List</h1>
    <div class="d-flex mt-4 ml-0" style="gap: 10px">
      <v-autocomplete
        v-model="locationID"
        item-text="location"
        append-icon="keyboard_arrow_down"
        item-value="locationID"
        :items="centers"
        label="Center"
        outlined
        class="multiple-select"
        dense
      >
      </v-autocomplete>
      <v-autocomplete
        label="Class status"
        v-model="classStatusID"
        append-icon="keyboard_arrow_down"
        :items="statusList"
        item-text="classStatus"
        item-value="classStatusID"
        outlined
        dense
        hide-details
        class="multiple-select"
      ></v-autocomplete>
      <v-autocomplete
        label="Class code"
        v-model="classID"
        append-icon="keyboard_arrow_down"
        :items="classList"
        item-text="classCode"
        item-value="classID"
        outlined
        dense
        hide-details
        class="multiple-select"
      ></v-autocomplete>
      <v-btn color="primary" @click="searchData">Search</v-btn>
    </div>
    <template v-if="isLoading">
      <div class="pa-6">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </div>
    </template>
    <v-data-table
      v-if="!isLoading"
      :headers="finalHeader.header"
      :items="filtered"
      :items-per-page="1000000"
      hide-default-header
      hide-default-footer
      class="elevation-0 mb-5 bookTable"
      fixed-header
      height="79vh"
      id="bookTable"
    >
      <template v-slot:header="{}">
        <thead style="border-radius: 20px; text-align: center" class="v-data-table-header">
          <tr>
            <th
              v-for="(header, index) in finalHeader.headerTitle"
              :key="index"
              style="
                background: #0b2585;
                color: #fff;
                text-align: center;
                border: 1px solid #dae3e8;
              "
              :colspan="header.child.length === 0 ? 1 : header.child.length"
              :rowspan="header.child.length === 0 ? 2 : 1"
              v-html="header.text"
            ></th>
          </tr>
          <tr>
            <template v-for="(header, index) in finalHeader.headerTitle">
              <th
                :key="index + headerChild"
                v-for="headerChild in header.child"
                style="height: 48px !important; text-align: center; border: 1px solid #dae3e8"
                v-html="headerChild"
              ></th>
            </template>
          </tr>
        </thead>
        <tr class="fixed-book-filter" :style="{ top: materialList.length > 0 ? '96px' : '48px' }">
          <th v-for="header in finalHeader.header" :key="header.value">
            <AutoFilter
              v-if="autoFilters.hasOwnProperty(header.value)"
              @setFilter="setAutoFilter"
              :styleBorder="'border-bottom'"
              :keyFilter="header.value"
              :selectedInit="autoFilters[header.value]"
              :listItem="groupColumnValueList(header.value)"
            />
          </th>
        </tr>
      </template>
      <template v-slot:body="{ items }">
        <tbody class="custom-tbody">
          <tr v-for="(item, index) in items" :key="index">
            <!-- Your custom table row content here -->
            <td v-for="(value, index) in Object.keys(tableBody)" :key="index">
              {{ item[value] }}
            </td>
            <!-- <td>{{ item.studentName }}</td> -->
            <!-- Other columns -->
          </tr>
        </tbody>
        <tfoot v-if="materialList.length > 0">
          <tr class="bookTableFoot">
            <td class="text-h6" colspan="4" style="text-align: left !important">Grand Total</td>
            <td v-for="(key, index) in Object.keys(filteredPayment)" :key="index">
              {{ filteredPayment[key] }}
            </td>
          </tr>
        </tfoot>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { class_managerment } from '@/api/class-management.js'
import { book_management } from '@/api/book_management.js'
import dayjs from 'dayjs'
import QueryString from 'qs'
import { getObjectPermission } from '../../common/index'
// import data from './data.json'
// import data2 from './header.json'
import AutoFilter from '@/components/Utils/Autocomplete.vue'

export default {
  components: {
    AutoFilter,
  },
  data() {
    return {
      currentRole: JSON.parse(localStorage.getItem('currentUser')).userInfo.accountTypeID,
      classStatusID: 3,
      valid: false,
      permision: getObjectPermission(401),
      centers: JSON.parse(localStorage.getItem('currentUser')).locationPermissions,
      locationID: JSON.parse(localStorage.getItem('currentCenter')),
      statusList: [],
      classList: [],
      inventoryList: [],
      materialList: [],
      //filter in table
      autoFilters: {
        studentCode: [],
        studentName: [],
        dob: [],
      },
      tableBody: {
        no: [],
        studentCode: [],
        studentName: [],
        dob: [],
      },
      //setting table
      headers: [
        { text: 'No', align: 'center', value: 'no', width: '50px', sortable: false },
        {
          text: 'Student Code',
          value: 'studentCode',
          width: '150px',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Student Name',
          value: 'studentName',
          align: 'center',
          width: '210px',
          sortable: false,
        },
        {
          text: 'DOB',
          value: 'dob',
          align: 'center',
          width: '150px',
          sortable: false,
        },
      ],
      isLoading: false,
      classID: 5054,
      classCode: '',
      startDate: '',
    }
  },
  computed: {
    filteredPayment() {
      const grandTotal = {}
      this.materialList.forEach(material => {
        Object.assign(grandTotal, {
          ['overallPaid' + material.productID]: 0,
          ['overallReceived' + material.productID]: 0,
          ['overallReceivedDate' + material.productID]: '-',
        })
      })
      this.filtered?.forEach(el =>
        this.materialList.forEach(d => {
          if (el['paid' + d.productID] && el['received' + d.productID]) {
            grandTotal['overallPaid' + d.productID] += el['paid' + d.productID]
            grandTotal['overallReceived' + d.productID] += el['received' + d.productID]
          }
        })
      )
      return Object.fromEntries(
        Object.entries(grandTotal).sort((a, b) => {
          const typeOrder = {
            overallPaid: 1,
            overallReceived: 2,
            overallReceivedDate: 3,
          }
          const indexA = parseInt(a[0].match(/\d+/)[0])
          const indexB = parseInt(b[0].match(/\d+/)[0])
          const typeA = a[0].match(/[a-zA-Z]+/)[0]
          const typeB = b[0].match(/[a-zA-Z]+/)[0]
          if (indexA !== indexB) {
            return indexA - indexB
          } else {
            return typeOrder[typeA] - typeOrder[typeB]
          }
        })
      )
    },
    filtered() {
      return this.finalData?.filter(d => {
        return Object.keys(this.autoFilters).every(f => {
          return this.autoFilters[f].length < 1 || this.autoFilters[f].includes(d[f])
        })
      })
    },
    finalData() {
      this.inventoryList.studentList?.forEach(el => {
        el.materialList?.forEach(material => {
          Object.assign(el, {
            ['paid' + material.materialID]: material.paid,
            ['received' + material.materialID]: material.received,
            ['receivedDate' + material.materialID]: material.receivedDate
              ? dayjs(material.receivedDate).format('DD/MM/YYYY')
              : '-',
          })
        })
        delete el.materialList
      })
      return this.inventoryList?.studentList?.map((el, index) => {
        return {
          ...el,
          no: index + 1,
          dob: dayjs(el.dob).format('DD/MM/YYYY'),
        }
      })
    },
    finalHeader() {
      const header = [
        { text: 'No', align: 'center', value: 'no', width: '50px', sortable: false },
        {
          text: 'Student Code',
          value: 'studentCode',
          width: '180px',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Student Name',
          value: 'studentName',
          align: 'center',
          width: '200px',
          sortable: false,
        },
        {
          text: 'DOB',
          value: 'dob',
          align: 'center',
          width: '100px',
          sortable: false,
        },
      ]
      const headerTitle = header.map(el => {
        return {
          text: el.text,
          child: [],
        }
      })
      this.materialList?.forEach(el => {
        headerTitle.push({
          text: el.product,
          child: ['Paid', 'Received', 'Received Date'],
        })
      })
      //add value to headerValue for filtering purpose
      this.materialList?.forEach(el => {
        header.push({
          text: 'Paid',
          value: 'paid' + el.productID,
          sortable: false,
          align: 'center',
          width: '50px',
        })
        header.push({
          text: 'Received',
          value: 'received' + el.productID,
          sortable: false,
          align: 'center',
          width: '50px',
        })
        header.push({
          text: 'Received Date',
          value: 'receivedDate' + el.productID,
          sortable: false,
          align: 'center',
          width: '100px',
        })
      })
      return { headerTitle, header }
    },
  },
  async created() {
    await this.searchClass()
    await this.init()
  },

  methods: {
    searchData() {
      ;(this.autoFilters = {
        studentCode: [],
        studentName: [],
        dob: [],
      }),
        (this.tableBody = {
          no: [],
          studentCode: [],
          studentName: [],
          dob: [],
        }),
        this.init()
    },
    async searchClass() {
      let body = {}
      await class_managerment.getClassStatus().then(res => {
        if (res) {
          res.unshift({
            classStatusID: 6,
            classStatus: 'All',
          })
          this.statusList = res
          this.classStatus = this.classStatusID < 6 ? res[this.classStatusID].classStatus : ''
          body = {
            pLocationid: this.locationID,
            pStatus: this.classStatus,
          }
        }
      })

      await class_managerment.getClassByLocation(QueryString.stringify(body)).then(res => {
        if (res) {
          this.classList = res
        }
      })
    },
    setAutoFilter(objectFilterChange) {
      this.autoFilters = {
        ...this.autoFilters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
    },
    groupColumnValueList(val) {
      if (val.includes('paid') || val.includes('receivedDate') || val.includes('received'))
        return [...new Set(this.finalData.map(d => d[val]))]
      return this.finalData?.map(d => d[val]).filter(y => y)
    },
    async init() {
      this.isLoading = true
      await book_management.getMaterialCheckList(this.classID).then(res => {
        if (res.courseMaterialByClass.length > 0) {
          this.materialList = res.courseMaterialByClass
          res.courseMaterialByClass?.forEach(el => {
            const object = {
              ['paid' + el.productID]: [],
              ['received' + el.productID]: [],
              ['receivedDate' + el.productID]: [],
            }
            this.autoFilters = {
              ...this.autoFilters,
              ...object,
            }
            this.tableBody = {
              ...this.tableBody,
              ...object,
            }
          })
        } else this.materialList = []
        if (res.studentList.length > 0) this.inventoryList = res
        this.isLoading = false
      })
    },

    _dayjs: dayjs,
  },
}
</script>

<style lang="scss" scoped>
.book-inventory h1 {
  font-size: 21px;
}
#bookTable {
  td {
    text-align: center;
  }
}
.select-item {
  // margin-right: 10px;
  max-width: 200px;
}
.multiple-select {
  margin-right: 10px;
  max-width: 200px;
}

.book-inventory {
  margin: 0 20px;
}

.row-overall {
  td {
    text-align: center;
    p {
      font-weight: bold;
      display: inline-block;
    }
  }
}
.row-sale {
  background: #f3f3f3;
  td {
    text-align: center;
  }
}
.bookTableFoot {
  td {
    text-align: center;
    font-weight: bold;
  }
}
// .fixed-book-filter {
//   position: sticky;
//   z-index: 2;
//   background-color: #fff;
//   & th:nth-of-type(1) {
//     // position: sticky;
//     z-index: 4 !important;
//     left: 0;
//   }
//   & th:nth-of-type(2) {
//     // position: sticky;
//     z-index: 4 !important;
//     left: 50px;
//   }
//   & th:nth-of-type(3) {
//     // position: sticky;
//     z-index: 4 !important;
//     left: 170px;
//   }
// }
// .v-data-table-header {
//   tr {
//     th:nth-child(1) {
//       position: sticky;
//       left: 0;
//       width: 50px;
//       z-index: 6;
//     }
//     th:nth-child(2) {
//       position: sticky;
//       left: 50px;
//       width: 100px;
//       z-index: 6;
//     }
//     th:nth-child(3) {
//       position: sticky;
//       left: 150px;
//       width: 200px;
//       z-index: 6;
//     }
//     th:nth-child(4) {
//       position: sticky;
//       left: 350px;
//       width: 100px;
//       z-index: 6;
//     }
//   }
// }

.custom-tbody tr:nth-child(even) {
  background: #eaf1ff;
}
.custom-tbody {
  tr {
    td:nth-child(1) {
      width: 50px;
    }
    td:nth-child(2) {
      width: 150px;
    }
    td:nth-child(3) {
      width: 200px;
    }
    td:nth-child(4) {
      width: 150px;
    }
  }
}

// .exam-body tr:nth-child(even) td:nth-child(3) {
//   background: #eaf1ff !important;
// }
// .exam-body tr:nth-child(odd) td:nth-child(3) {
//   background: #fff !important;
// }
// .exam-body tr:nth-child(even) td:nth-child(2) {
//   background: #eaf1ff !important;
// }
// .exam-body tr:nth-child(odd) td:nth-child(2) {
//   background: #fff !important;
// }
// .exam-body tr:nth-child(even) td:nth-child(1) {
//   background: #eaf1ff !important;
// }
// .exam-body tr:nth-child(odd) td:nth-child(1) {
//   background: #fff !important;
// }
// .exam-body tr:nth-child(even) {
//   background: #eaf1ff !important;
// }
// .exam-body tr:nth-child(odd) {
//   background: #fff !important;
// }
</style>
